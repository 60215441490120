import { defineStore } from "pinia";

export const useAlertsStore = defineStore("alerts", {
  state: () => ({
    alert: {
      show: false,
      type: "",
      message: null,
      linkText: "",
      href: "#",
    },
  }),
  actions: {
    setAlert(type, message, linkText, href) {
      this.alert.type = type;
      this.alert.message = message;
      this.alert.show = true;
      this.alert.linkText = linkText;
      this.alert.href = href;
    },
    clearAlert() {
      setTimeout(() => {
        this.alert.show = false;
      }, 5000);
    },
  },
});
