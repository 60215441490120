<script setup>
import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
} from "@heroicons/vue/20/solid";

import { useAlertsStore } from "@/stores/alertsStore";

const alertsStore = useAlertsStore();
const alert = computed(() => alertsStore.alert);

const alertProps = computed(() => {
  switch (alert.value.type) {
    case "success":
      return {
        message: alert.value.message
          ? alert.value.message
          : "La acción se completo con éxito!",
        color: "green",
        icon: CheckCircleIcon,
        component: "Alert",
      };
    case "error":
      return {
        message: alert.value.message ? alert.value.message : "Un error ocurrió",
        color: "red",
        icon: ExclamationTriangleIcon,
        component: "Alert",
      };

    case "info-with-link":
      return {
        message: alert.value.message,
        color: "yellow",
        icon: InformationCircleIcon,
        component: "AlertWithLinkOnRight",
        textLink: alert.value.textLink
          ? alert.value.textLink
          : "Ver comentarios",
      };
    case "simple-info":
      return {
        message: alert.value.message,
        color: "yellow",
        icon: InformationCircleIcon,
        component: "Alert",
      };
    case "simple-info-disabled":
      return {
        message: alert.value.message,
        color: "gray",
        icon: InformationCircleIcon,
        component: "Alert",
      };

    case "warning-with-link":
      return {
        message: alert.value.message,
        color: "red",
        icon: InformationCircleIcon,
        component: "AlertWithLinkOnRight",
        textLink: alert.value.textLink
          ? alert.value.textLink
          : "Ver comentarios",
      };
    case "warning":
      return {
        message: alert.value.message,
        color: "red",
        icon: InformationCircleIcon,
        component: "Alert",
      };
    case "info-with-url-link":
      return {
        message: alert.value.message,
        color: "yellow",
        icon: InformationCircleIcon,
        component: "AlertWithURLLink",
        linkText: alert.value.linkText,
        href: alert.value.href,
      };

    default:
      return "alert-default"; // Una clase por defecto
  }
});
</script>

<template>
  <div v-if="alertProps.component" class="mx-1 my-1.5">
    <Alert
      v-if="alertProps.component === 'Alert'"
      :alert-props="alertProps"
      :show="alert.show"
    />
    <AlertWithLinkOnRight
      v-else-if="alertProps.component === 'AlertWithLinkOnRight'"
      :alert-props="alertProps"
    />
    <AlertWithURLLink
      v-else-if="alertProps.component === 'AlertWithURLLink'"
      :alert-props="alertProps"
    />
  </div>
</template>
