<script setup>
import { InformationCircleIcon } from "@heroicons/vue/20/solid";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/solid";
import { useOpenSectionStore } from "@/stores/openSectionStore";

defineProps({
  alertProps: {
    type: Object,
    default: () => ({
      message: "",
      color: "red",
      icon: ExclamationTriangleIcon,
      textLink: "",
    }),
  },
  // show: {
  //   type: Boolean,
  //   default: false,
  // },
});

const openStore = useOpenSectionStore();

const handleButtonClick = () => {
  openStore.openSection();
};
</script>

<template>
  <div :class="[`rounded-md bg-${alertProps.color}-50 p-4`]">
    <div class="flex">
      <div class="flex-shrink-0">
        <InformationCircleIcon
          :class="[`h-5 w-5 text-${alertProps.color}-400`]"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between">
        <p :class="[`text-sm text-${alertProps.color}-700`]">
          {{ alertProps.message }}
        </p>
        <p class="mt-3 text-sm md:ml-6 md:mt-0">
          <button
            type="button"
            :class="[
              `text-${alertProps.color}-700 hover:text-${alertProps.color}-600 whitespace-nowrap font-medium`,
            ]"
            @click="handleButtonClick"
          >
            {{ alertProps.textLink }}
            <span aria-hidden="true"> &rarr;</span>
          </button>
        </p>
      </div>
    </div>
  </div>
</template>
