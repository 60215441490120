import { defineStore } from "pinia";

export const useOpenSectionStore = defineStore("openSection", {
  state: () => ({
    isSectionOpen: false,
  }),
  actions: {
    openSection() {
      this.isSectionOpen = true;
    },
    closeSection() {
      this.isSectionOpen = false;
    },
  },
});
